/**
 * @tokens Font Weight
 * @presenter FontWeight
 */

@import url("https://use.typekit.net/spi0hje.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");
@font-face {
  src: url("../../assets/fonts/Arista-Pro-Alternate-Light-trial.ttf")
    format("truetype");
  font-family: "arista";
}

$fontPrimary: "Open Sans", sans-serif !important;
$fontLogo: "arista";
$fontSizeXSmall: 10px;
$fontSizeSmall: 11px;
$fontSizeMed: 12px;
$fontBaseSize: 13px;
$fontSize14: 14px;
$fontSize15: 1rem;
$fontSize16: 1.2rem;
$fontSize18: 18px;
$fontSize20: 20px;
$fontSize24: 24px;
$fontSize25: 25px;
$fontSize30: 30px;
$fontSize32: 32px;
$fontSize40: 40px;
$fontSize56: 56px;
$fontBaseLineHeight: 1.3;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
