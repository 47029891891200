.layout {
  color: $brandColorPrimary;
  width: 100%;

  .layout__content {
    min-height: 100vh;
    background-color: $bgColor;
  }

  .layout__content-main {
    width: 99%;
    padding-top: 6rem;
    transition: 850ms;
    padding-left: 265px;

    @include mQ($MaxLargScreen) {
      padding-left: 20px;
    }
  }
}
