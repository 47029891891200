@import "../../../../styles/global.main.scss";
.device {
  font-weight: bold;
  color: $brandColorSecondary;
}
.on {
  width: 80px;
  text-align: center;
  border-radius: 3px;
  background: rgb(91, 170, 13);
  color: #ffff;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.off {
  width: 80px;
  text-align: center;
  border-radius: 3px;
  background: rgb(207, 26, 26);
  color: #ffff;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

h5 {
  border-bottom: none !important;
  padding-top: 0 !important;
}

.profile-personal-info {
  font-size: 16px;
  padding: 1rem;
  h4 {
    font-size: 20px;
    padding: 20px 5px;
    padding-bottom: 0;
    margin-bottom: 2rem;
    border-bottom: 1px solid $brandColorPrimary;
  }
  h5 {
    font-size: 16px;
  }

  .pull-right {
    float: right;
  }

  i {
    margin-right: 10px;
  }
}
