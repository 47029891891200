@mixin clearFix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin mQ($list...) {
  @if length($list) == 1 {
    @media screen and (max-width: nth($list, 1)) {
      @content;
    }
  }

  @if length($list) == 2 {
    @media screen and (max-width: nth($list, 1)) and (min-width: nth($list, 2)) {
      @content;
    }
  }
}
