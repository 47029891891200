@import "spacing";
.btn {
  font-size: 13px !important;
}
button:hover {
  opacity: 0.7;
}
.btn-transparent-border-red {
  width: 200px;
  height: 44px;
  padding: $spacingXXS 0;
  border-radius: 26px !important;
  border: solid 1px $semanticColorError !important;
  background-color: transparent !important;
  color: $semanticColorError !important;
  font-weight: 600;
  &:focus,
  &:hover {
    border: 1px solid $semanticColorError !important;
  }
}
.btn-transparent-border-blue {
  width: 200px;
  height: 44px;
  padding: $spacingXXS 0;
  border-radius: 26px !important;
  border: solid 1px $primaryBlue !important;
  background-color: transparent !important;
  color: $primaryBlue !important;
  &:focus,
  &:hover {
    border: 1px solid $primaryBlue !important;
  }
}
.btn-back {
  width: 112px;
  height: 44px;
  padding: $spacingXXS $spacingXXS $spacingXXS $spacingXS !important;
  border-radius: 8px !important;
  border: solid 1px $placeholderGray !important;
  background-color: transparent;
  background-image: url(../../assets/images/arrow-left.svg);
  background-repeat: no-repeat;
  background-position: left 20px center;
  background-size: 8px;
  color: $fontColorBlack !important;
  font-size: $fontSize16;
  font-weight: 700;
  &:focus,
  &:hover {
    border: 1px solid $primaryBlue !important;
    background-image: url(../../assets/images/arrow-left-blue.svg);
    background-repeat: no-repeat;
    background-position: left 20px center;
    background-size: 8px;
    color: $primaryBlue !important;
    opacity: 1;
  }
}
.btn-white-border-black {
  width: 152px;
  height: 42px;
  padding: $spacingXXS 0;
  border-radius: 26px !important;
  border: solid 1px $borderColorLightGray !important;
  background-color: $generalColorWhite !important;
  color: $fontColorBlack !important;
  &:focus,
  &:hover {
    border: 1px solid $borderColorLightGray !important;
  }
  &.danger {
    border-color: $fontColorError !important;
    color: $fontColorError !important;
  }
}
.btn-white {
  background-color: $backgroundColorGray;
  width: 192px;
  height: 52px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 18px;
  padding: $btnWhiteSpace;
  border-radius: 26px;
  box-shadow: 0 5px 15px 0 rgba(167, 190, 255, 0.2) inset;
  border: solid 1px $borderColorLightGray !important;
  &:hover {
    background-color: $brandColorPrimary;
    color: $generalColorWhite;
  }
}
.btn-rounded-small {
  background-color: $generalColorWhite;
  width: 180px;
  height: 44px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 38px;
  padding: 0.2rem 0;
  color: $fontPrimaryColor;
  border-radius: 4px;
  border: solid 1px $borderColorLightGray !important;
  img {
    margin-top: -5px;
    margin-right: 0.7rem;
    height: 16px;
  }
  &:hover {
    color: $fontColorBlack;
  }
}
.btn-blue-gradient {
  width: 100%;
  height: 52px;
  margin-top: 3rem;
  padding: 1.75rem 0;
  color: $generalColorWhite !important;
  border-radius: 26px !important;
  font-size: 13px !important;
  box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.7);
  background-image: linear-gradient(to right, #1b84e7, #1515af 100%);
}

.btn-red {
  width: 100%;
  height: 52px;
  margin-top: 3rem;
  padding: 1.75rem 0;
  color: $generalColorWhite !important;
  border-radius: 26px !important;
  font-size: 13px !important;
  box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.7);
  background-color: $semanticColorError;
}
.btn-green {
  background-image: none;
  background-color: $semanticColorSuccess !important;
  width: 192px;
  margin-right: $spacingXS;
}

.btn-blue {
  background-color: $primaryBlue !important;
  border-radius: 26px !important;
  color: $generalColorWhite !important;
  box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.7) !important;
}
.btn-steps {
  width: 192px;
  height: 45px;
  padding: 1.25rem 0;
  margin-top: 4rem;
}

.btn-text-blue {
  color: $primaryBlue !important;
  font-weight: bold !important;
  background-color: transparent !important;
}
.btn-small {
  width: 170px;
  height: 45px;
  padding: 1.15rem 0;
}
.btn-x-small {
  width: 160px;
  height: 32px;
  padding: $spacingXXXS $spacingXXS !important;
}

.btn-transparent {
  background-color: transparent !important;
  box-shadow: 0 5px 15px 0 rgba(167, 190, 255, 0.5);
  border-radius: 26px !important;
  color: $primaryBlue !important;
}
.btn-icon {
  width: auto;
  height: auto;
  background: transparent !important;
}

.btn-add-circle-blue {
  border-radius: 50px;
  width: 44px;
  height: 44px;
  border: 1px solid $primaryBlue !important;
  font-size: 32px;
  color: $primaryBlue;
  background: transparent !important;
  position: absolute;
  top: -10px;
  right: 0;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:hover {
    border: 1px solid $primaryBlue !important;
  }
}
.btn-circle-red {
  border-radius: 50px !important;
  width: 44px;
  height: 44px;
  border: 1px solid $semanticColorError !important;
  font-size: 32px;
  background: transparent !important;
  &:focus,
  &:hover {
    border: 1px solid $semanticColorError !important;
  }
}
.period-selector {
  label {
    font-size: 1.2rem;
    cursor: pointer;
    display: block;
    margin: 1em;
    > input {
      display: none;
    }

    span {
      color: $primaryBlue !important ;
      font-size: $fontBaseSize;
      font-weight: bold;
      padding: 0 $spacingXS;
    }

    i {
      display: inline-block;
      width: 77px;
      height: 24px;
      border-radius: 20px;
      vertical-align: middle;
      transition: 0.25s 0.09s;
      position: relative;
      background: $primaryBlue;
      &:after {
        content: " ";
        display: block;
        width: 23px;
        height: 23px;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background: $generalColorWhite;
        border: 1px solid $primaryBlue;
        position: absolute;
        transition: 0.15s;
      }
    }
    > input:checked + i {
      background: $placeholderGray;
    }
    > input:checked + i:after {
      transform: translateX(53px);
    }
  }
}
