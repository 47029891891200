@import "../../styles/global.main.scss";

.wrapper-buttons {
  border-top: 0.5px solid rgb(180, 178, 178);
  margin-top: 2rem;
  align-self: flex-end;
  text-align: end;
  // padding: 0.5rem 0.5rem;

  button {
    margin-top: 1rem;
    margin-left: 1rem;
    background-color: $brandColorPrimary;
    border-radius: 6px;
  }
}

.add-button {
  text-align: end;
}

.no-permissions {
  font-size: 13px;
  font-weight: bold;
}
.title {
  h5 {
    font-size: large;
    padding-bottom: 0.5rem;
    padding-top: 0rem !important;
    border-bottom: 1px solid rgb(175, 175, 175) !important;
  }
}

.edit-permission {
  padding: 1rem;
  h5 {
    font-size: large;
    padding-bottom: 0.5rem;
    padding-top: 0rem !important;
    border-bottom: 1px solid rgb(175, 175, 175) !important;
    color: rgb(73, 43, 43);
  }
  .padding-top {
    padding-top: 2rem;
  }
}

.current-permission {
  h6 {
    // text-transform: uppercase;
    // font-weight: bold;
    margin-left: $spacingXXXS;
    color: $fontColorBlack;
    width: 200px;
    &:before {
      margin-top: 3px;
      width: 10px;
      height: 10px;
      left: 250px;
      content: "";
      position: absolute;
      background-color: $brandColorPrimary;
    }
  }
}

h5 {
  padding-top: 2rem;
  border-bottom: 1px solid rgb(175, 175, 175);
}

.group-list {
  font-size: 14px;
  .is-active {
    color: $semanticColorSuccess;
    font-size: 14px;
    font-weight: 600;
  }
  .is-inactive {
    color: $semanticColorError;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      color: #ffffff;
    }
  }

  .focus {
    font-weight: bold;
  }

  button {
    border: none;
    padding-left: 60px;
  }

  .dots-loading {
    margin-right: 10px;
    padding: 0;
    -webkit-animation: fadein 0.5s ease-in alternate infinite;
    -moz-animation: fadein 0.5s ease-in alternate infinite;
    animation: fadein 0.5s ease-in alternate infinite;
    @-webkit-keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
    @-moz-keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
  }
}
