@import "../../styles/global.main.scss";

.container-apk {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  // grid-auto-rows: 50px;
  gap: 0.75rem;
}

.wrapper {
  min-height: 90vh;
}

header {
  background-color: rgb(202, 212, 247);
  .logo {
    height: 8rem;
    width: 10rem;
    // float: left;
  }
  border-bottom: 2px solid #0f4876;
  box-shadow: 0px 1px 600px 300px $brandColorPrimary;
  span {
    font-family: $fontLogo;
    font-size: $fontSize56;
    color: $brandColorPrimary;
    font-weight: bold;
    text-decoration: none;
    // text-align: left;
    padding-left: 100px;
    @include mQ($mobileMax) {
      padding-left: 20px;
    }
  }
}

.title-app {
  font-size: 24px;
  text-align: center;
  font-weight: bolder;
  // background: #0f4876;
  color: rgb(179, 189, 218);
  justify-content: center;
}

.content {
  padding-top: 3rem;
  text-align: center;
  h3 {
    color: white;
  }
}

.spiner {
  // padding-top: 9rem;
  justify-content: center;
  text-align: center;
  align-content: center;
}

.content-card {
  padding: 2rem;
  .download {
    color: #0f4876;
    font-weight: 800;
  }
}
