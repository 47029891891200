@import "../../styles/global.main.scss";

.table-text {
  font-size: 14px;
  .is-info {
    color: $semanticColorSuccess;
    font-size: 14px;
    font-weight: 600;
  }
  .is-warn {
    color: $semanticColorWarning;
    font-size: 14px;
    font-weight: 600;
  }
  .is-error {
    color: $semanticColorError;
    font-size: 14px;
    font-weight: 600;
  }
}
