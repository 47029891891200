@import "../../../../styles/global.main.scss";

.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;
}

.custom-pagination {
  li {
    display: inline-block;
    .paginate-buttons {
      margin: 3px;
      border: 1px solid $placeholderGray;
      border-radius: 6px;
      padding: 1px 7px;
      background-color: $generalColorWhite;
      font-weight: 600;
      font-size: $fontBaseSize;
      color: $brandColorPrimary;
      height: 30px;
      &.active {
        border: 2px solid $brandColorPrimary;
        color: $brandColorPrimary;
      }
    }
  }
}

.btn-secondary {
  background-color: $brandColorPrimary !important;
  min-width: 60px !important;
  min-height: 25px;
}

.paginate-dropdown {
  text-decoration: none;
  width: 100%;
  padding: 0 3px;
  color: $brandColorPrimary;
  font-size: 14px;
}

.parent-expand {
  background-color: #28689c;
  color: white !important;
}

.child-expand {
  thead,
  tbody {
    th,
    tr {
      color: $brandColorPrimary;
      text-align: center;
    }
  }
}
.wrapper-cl {
  border: 1px solid #458bc4;
}

// table td {
//   word-wrap: break-word;
//   white-space: break-spaces !important;
// }

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  table {
    border: 0 !important;
  }

  table thead {
    display: none;
  }

  table tr {
    margin-bottom: 10px;
    display: block;
    padding: 5px;
    border-bottom: 1px solid #ddd;
  }
  table tr:nth-child(odd) {
    // background: rgba(232, 232, 232, 0.58);
    border-bottom: 1px solid rgba(215, 213, 213, 0.71);
  }

  table tr:last-child {
    border-bottom: 0 !important;
  }

  table td {
    display: block;
    text-align: right !important;
    font-size: 12px;
    word-wrap: break-word;

    border: none;
    border-bottom: 1px solid #eee;
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bolder;
  }
  tbody {
    // line-height: 0 !important;
  }

  .react-bootstrap-table-page-btns-ul {
    margin-top: 20px;
    float: left !important;
  }
  .react-bootstrap-table-pagination-total {
    margin-top: 20px;
    display: block;
  }
}
