@import "../../../styles/global.main.scss";

.header {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  background: $brandColorPrimary;
  box-shadow: 0px 0px 6px 0px $brandColorPrimary;
  z-index: 999;
  padding: 0 $spacingXS;
  justify-content: space-between;
  transition: 850ms;

  @include mQ($mobileMax) {
    padding: 0 $spacingXXS;
  }

  div {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      span {
        font-family: $fontLogo;
        font-size: $fontSize56;
        color: #ffff;
        font-weight: bold;
        text-decoration: none;
        min-width: 120px;
        // text-align: left;
        padding-left: 20px;
        @include mQ($mobileMax) {
          padding-left: 0;
        }
      }

      .nav-toggle {
        display: none;
        margin-left: 2rem;
        font-size: 40px;
        color: #ffff;
        cursor: pointer;
        @include mQ(($MaxLargScreen)) {
          display: flex;
        }
        @include mQ($mobileMax) {
          padding-left: 20px;
        }
      }
    }

    .dropItem {
      font-size: $fontSize14;
      padding-left: $spacingXXS;
      font-family: $fontPrimary;
      color: $brandColorPrimary;
    }

    .clock {
      color: #ffff;
      margin-right: 1rem;
      font-size: 12px;
      text-align: center;
      font-weight: bold;
    }

    &:nth-child(2) {
      display: flex;
      align-items: center;
      div {
        width: 100%;
        span {
          font-size: $fontSize14;
          padding-left: $spacingXXS;
          font-family: $fontPrimary;
        }
        .avatar {
          height: $spacingXS + 0.5rem;
          width: $spacingXS + 0.5rem;
          // @include mQ($mobileMax) {
          //   height: $spacingXS;
          //   width: $spacingXS;
          // }
        }
      }
      img {
        margin: 0 $spacingXXXS;
        padding-left: 1rem;
      }
      @include mQ($mobileMax) {
        a {
          display: none;
        }
        .clock {
          display: none;
        }
      }
    }
  }
}

.sidebar {
  background-color: #fff;
  padding: $spacingXS 0;
  width: 240px;
  box-shadow: 0px 0px 10px 0px $brandColorPrimary;
  position: fixed;
  inset: 70px 0 0 0;
  transition: 850ms;
  padding-left: $spacingXXXS;
  z-index: 100;
  height: 95vh;
  @include mQ($MaxExtraScreen) {
  }

  @include mQ(($MaxLargScreen)) {
    inset: 70px 0 0 -100%;
    &.active {
      height: 95vh;
      inset: 70px 0 0 0;
    }
  }
  @include mQ(($MaxMobile)) {
    height: fit-content;
    inset: -100% 0 0 0;
    width: 100%;
    &.active {
      height: fit-content;
      inset: 70px 0 0 0;
    }
  }

  .nav-menu-items {
    padding-top: $spacingXXS;
    list-style: none;
    .sub-menu {
      margin: 0;
      margin-left: 0.7rem;
      li {
        list-style: none;
        a {
          text-decoration: none;
          color: $fontColorDarkGray;
          font-size: $fontSize14;
          width: 100%;
          height: 100%;
          padding: 0.5rem 0;
          border-radius: 4px;
          padding: $spacingXXXS 0;
          &::before {
            content: "";
            border: 1px solid $fontColorDarkGray;
            height: 10%;
            width: 5px;
            margin-right: 10px;
          }
          &:hover {
            background-color: rgb(127, 154, 179);
            box-shadow: 1px 2px 7px 1px rgb(94, 84, 84);
            color: white;
            font-weight: bolder;
          }
          &.active {
            &::before {
              border: 3px solid $brandColorPrimary;
            }
            color: $brandColorPrimary;
            font-weight: bolder;
            font-size: 16px;
          }
        }
      }
    }

    li {
      padding: 4px 0;
      a {
        padding: $spacingXXS 0;
        font-size: $fontSize15;
        text-decoration: none;
        color: $fontColorDarkGray;
        width: 95%;
        height: 100%;
        display: block;
        align-items: center;
        border-radius: 4px;
        svg {
          display: inline-block;
          margin-right: $spacingXXXS;
          margin-top: -0.3rem;
          fill: #495057;
          width: 25px;
        }
        &.active {
          color: $brandColorPrimary;
          font-weight: bold;
          svg {
            fill: $brandColorPrimary;
          }
        }
        span {
          float: right;
        }
      }
    }
  }

  ul {
    li {
      padding: $spacingXXS + 0.5 0;
      cursor: pointer;
    }
    a {
      font-size: $fontSize16;
      color: $brandColorPrimary;
      line-height: 1rem;
      img {
        margin-right: $spacingXXS;
      }
      &.active {
        color: $brandColorPrimary;
        font-weight: bold;
      }
    }
  }
}
