.image-container {
  margin-right: 2rem;
  color: white;
  .image {
    padding-bottom: 10px;
    justify-content: flex-end;
  }
}

.add-release {
  text-align: start;
  padding-right: 2rem;

  div {
    font-size: 16px;
    color: red;
    display: flex;
    p {
      padding-right: 0.5rem;
    }
  }
}

.card-header:first-child {
  height: 20px;
  background-color: #0b557f;
}

.card-footer:last-child {
  text-align: end;
  .btn-secondary {
    color: black;
    background-color: rgb(185, 185, 185);
  }
  .btn-success {
    color: white;
    background-color: rgb(16, 161, 16);
  }
}

.app-list {
  font-size: 14px;
  .focus {
    font-weight: bold;
  }

  .dots-loading {
    margin-right: 10px;
    padding: 0;
    -webkit-animation: fadein 0.5s ease-in alternate infinite;
    -moz-animation: fadein 0.5s ease-in alternate infinite;
    animation: fadein 0.5s ease-in alternate infinite;
    @-webkit-keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
    @-moz-keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
  }
}
