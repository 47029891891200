@import "../../../../styles/global.main.scss";

.custom-button {
  // background-color: #ffff;
  // border-color: none;
  // color: white;
  // border-radius: 6px;
  // font-size: 14px;
  // height: 30px;
  // min-width: 100px;

  border-radius: 0;
}

.custom-button.reset {
  background-color: #797979 !important;
}

.custom-button.cancel {
  background-color: #742d2d !important;
}

.custom-button.map {
  background-color: saddlebrown;
}
