@import "../../../../styles/global.main.scss";

.btn {
  padding: 0px !important;
  margin: 0 !important;
}

.custom-dropdown {
  position: relative;
  width: auto;
  max-width: 140px;
  display: inline-block;
  // height: 2rem;
  z-index: 300;

  .btn-link {
    color: $fontColorBlack;
    font-weight: bold;
    text-decoration: none;
  }
  .dropdown-items {
    position: absolute;
    bottom: -30px;

    right: 60px;
    z-index: 1;
    min-width: 160px;
    padding: 10px 1px 8px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(167, 190, 255, 0.3);
    border: solid 1px $placeholderGray;
    background: #fff;
    justify-content: left;
    button {
      display: block;
      width: 100%;
      border-radius: 6px;
      border: none;
      text-align: left;
      background: transparent;
      padding: 5px $spacingXXS;
      font-size: $fontBaseSize;
      color: $brandColorPrimary;
      &:hover {
        background: #3c6d92;
        color: #ffffff !important;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
