.profile {
  .box {
    // background: #fff;
    // height: 10rem;
    // width: 100%;
    // box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.04);
    // border-radius: 0.5rem 0.5rem;
  }

  .profile-img {
    margin: auto;
    width: 100px;
    height: 100px;
  }
  .profile-wrapper {
    text-align: center;
  }
  h3 {
    margin-top: 20px;
  }
}

// .container-form {
//   padding: 2rem;
//   display: grid;
//   grid-template-columns: repeat(2, minmax(5rem, 2fr));
//   // grid-auto-rows: 50px;
//   width: 75%;
//   gap: 0.75rem;
// }
