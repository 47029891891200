@import "../../styles/global.main.scss";

.cdr-table {
  position: relative;
  font-size: 15px;
  .is-info {
    color: $semanticColorSuccess;
    font-size: 14px;
    font-weight: 600;
  }
  .is-warn {
    color: $semanticColorWarning;
    font-size: 14px;
    font-weight: 600;
  }
  .is-error {
    color: $semanticColorError;
    font-size: 14px;
    font-weight: 600;
  }

  .focus {
    font-weight: bold;
  }

  table {
    font-size: 13px;
    padding: 0;
  }
}
