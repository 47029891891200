@import '../../styles/global.main.scss';

.auth-form {
  margin: 30px 0px;
  width: 100%;
  padding-bottom: 2rem;
  .logo-style {
    font-family: $fontLogo;
    font-size: 100px;
    color: $brandColorPrimary;
    font-weight: bold;
    text-decoration: none;
    transition: 850ms;

    @include mQ($mobileMax) {
      font-size: 80px;
    }
  }

  a {
    text-decoration: none;
    color: unset;

    &:hover {
      color: $brandColorPrimary;
    }
  }

  .text-center-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-button {
    display: flex;
    color: white;
    background-color: $brandColorPrimary;
    margin-top: 40px;
    height: 30px;
    min-width: 100px;
    width: 100px;
    font-weight: 400;
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }

  .btn {
    background-color: $brandColorPrimary;
    margin-top: 20px;
    height: 40px;
    width: 120px;
    font-weight: 600;
    align-items: center;
  }
  .page-back {
    display: inline-block;
    margin-bottom: 15px;
  }

  .form-group {
    padding-bottom: 1rem;
    width: 100%;
    .form-control {
      display: block;

      height: calc(1.5em + 0.75rem + 2px);
      // padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d7dae3;
      border-radius: 0.75rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .form-error {
    text-align: center;
    font-size: 16px;
  }
}

.authincation-content {
  margin-top: 4rem;
  background: ffff;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 5px;
  padding: 0 5rem;

  @include mQ($mobileMax) {
    padding: 0 2rem;
  }

  .privacy-policy {
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
      // color: white !important;
    }
  }
}
