@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "./variables-custom/fonts";
@import "./variables-custom/colors";
@import "./variables-custom/buttons";
@import "./variables-custom/queries";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import "./mixins";
@import "layout";

$form-check-input-checked-bg-color: $brandColorPrimary;
$input-font-size: $fontBaseSize;
$input-focus-box-shadow: 0 0 4px #1c2631;
$form-select-focus-box-shadow: 0 0 4px #1c2631;

@import "../../node_modules/bootstrap/scss/forms/form-check";
@import "../../node_modules/bootstrap/scss/forms/form-select";
@import "../../node_modules/bootstrap/scss/forms/form-control";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

html {
  background: $bgColor;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: $fontBaseSize !important;
  font-family: $fontPrimary;
  line-height: $fontBaseLineHeight;
}

.error {
  color: rgb(175, 41, 41);
  font-size: small;
}

.title {
  font-weight: bolder;
  font-size: 20px;
  text-transform: uppercase;
  color: #0f4876;
  // border-bottom: 1px solid #0f4876;
  margin-bottom: 1rem;
  &:before {
    width: 7px;
    height: 33px;
    left: $spacingXS;
    content: "";
    position: absolute;
    background-color: $brandColorPrimary;
  }
}

.react-datepicker__input-container {
  &:after {
    background-image: url("../assets/icons/calendar-tick-18x18.png");
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    padding: 0 5px;
    width: 30px;
    height: 100%;
    position: absolute;
    text-align: center;
    top: 20%;
    right: 0;
  }
}

.fade {
  padding: 70px 0;
}

button {
  border: none;
  background-color: $brandColorPrimary;
  color: white;
  padding: 6px 15px;
  // border-radius: 4px;
  &:disabled {
    background-color: $gray-600;
  }
}
