@import "../../styles/global.main.scss";

.wrapper-buttons {
  border-top: 0.5px solid rgb(180, 178, 178);
  margin-top: 2rem;
  align-self: flex-end;
  text-align: end;
  // padding: 0.5rem 0.5rem;

  button {
    margin-top: 1rem;
    margin-left: 1rem;
    background-color: $brandColorPrimary;
    border-radius: 6px;
  }
}

.dots-loading {
  margin: 0;
  padding: 0;
  -webkit-animation: fadein 0.5s ease-in alternate infinite;
  -moz-animation: fadein 0.5s ease-in alternate infinite;
  animation: fadein 0.5s ease-in alternate infinite;
  @-webkit-keyframes fadein {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }
}
