@import "../../../../styles/global.main.scss";

.fade {
  padding: 200px 0;

  .modal-header {
    padding: 1rem 2rem;
    color: $brandColorPrimary;
    font-weight: bolder;
  }

  .modal-body {
    padding: 1rem;
    color: $brandColorPrimary;
    // font-weight: bold;
    // float: left;
    // span {
    //   color: red;
    // }
  }

  .close-modal {
    width: 30px !important;
    height: 30px !important;
    border: 0px !important;
    border-radius: 6px;
    background-color: rgb(194, 194, 194) !important;
    padding: 0 !important;
    span {
      text-align: center;
      font-size: 20px;
      color: white;
    }
  }

  // .modal-footer {
  //   padding: 1rem 2rem;
  //   button {
  //     font-weight: bolder;
  //     background-color: $semanticColorError;
  //     height: 30px;
  //     width: 80px;
  //     border-radius: 6px;
  //     &:hover {
  //       background-color: rgb(197, 133, 133);
  //       color: black;
  //     }
  //   }
  // }
}
